import {faBell, faHistory, faHome, faInfinity, faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import {loader} from 'graphql.macro';
import {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useParams} from 'react-router-dom';
import Switch from 'react-switch';
import './App.scss';
import {AccountActivity} from './component/AccountActivity';
import {BlogDisplay} from './component/BlogDisplay';
import {BlogGrid} from './component/BlogGrid';
import {LinkWithQuery} from './component/LinkWithQuery';
import {PosterExtension} from './component/PosterExtension';
import {Toaster} from './component/Toaster';
import {ActionType, StateContext, StateReducer} from './global/store';
import {graphRequest} from './global/util';
import {StateSubject} from './observer/all';

const SelfQuery = loader('./graph/self.query.graphql').loc.source.body;

const withClasses = (object: any) => {
	return Object.keys(object).filter((key) => {
		return object[key];
	})
		.join(' ');
};

const Store = (props) => {
	const [state, dispatch] = useReducer(StateReducer, {});
	useEffect(() => {
		const subscriber = StateSubject.subscribe(async () => {
			try {
				const data = await graphRequest(SelfQuery, {}, false);
				dispatch({
					type: ActionType.UPDATE_USER,
					payload: data.data.self
				});
				localStorage.setItem('loggedIn', 'true');
			} catch (error) {
				console.log(error);
				localStorage.removeItem('loggedIn');
			}
		});
		return () => {
			subscriber.unsubscribe();
		};
	}, []);
	useEffect(() => {
		StateSubject.next();
	}, []);
	return <StateContext.Provider value={[state, dispatch]}>{props.children}</StateContext.Provider>;
};

const Header = () => {
	const [state] = useContext(StateContext);
	return <header>
		<h1 className='red'>
			<LinkWithQuery to={'/'}>Queue System</LinkWithQuery>
		</h1>
		<p>Automate your Blogs with Ease</p>
	</header>;
};

const Main = ({children}) => {
	const [state, dispatch] = useContext(StateContext);

	const navigation = useMemo(() => {
		if (state.user) {
			return <nav>
				<ul>
					<li>
						<LinkWithQuery to={'/'} className='black'><FontAwesomeIcon icon={faHome}/></LinkWithQuery>
					</li>
					<li>
						<LinkWithQuery to={'/poster'} className='black'><FontAwesomeIcon icon={faHistory}/></LinkWithQuery>
					</li>
					<li>
						<LinkWithQuery to={'/activity'} className='black'><FontAwesomeIcon icon={faBell} /></LinkWithQuery>
					</li>
					<li>
						<a href='/api/google/logout' className='black'><FontAwesomeIcon icon={faSignOutAlt}/></a>
					</li>
				</ul>
			</nav>;
		};
		return <nav>
			<ul>
				<li>
					<a href={'/api/google/connect'} className='black'><FontAwesomeIcon icon={faSignInAlt}/></a>
				</li>
			</ul>
		</nav>;
	}, [state.user]);

	return <main>
		<div>{navigation}</div>
		<div>{children}</div>
	</main>;
};

const Dashboard = () => {
	const [state] = useContext(StateContext);

	const [hideSideBlogs, setHideSideBlogs] = useState<boolean>(localStorage.getItem('hideSideBlogs') ? true : false);

	const groups = useMemo(() => {
		if (state.user && state.user.blogs) {
			return state.user.blogs.reduce((prev, curr) => {
				if (prev[curr.group] === undefined) {
					prev[curr.group] = [];
				}
				prev[curr.group].push(curr);
				return prev;
			}, {});
		}
		return undefined;
	}, [state]);

	if (state.user) {
		return <div id='dashboard'>
			<div className='column left'>
				<h2>Dashboard <span className='plan-identifier'>{state.user.status}</span> </h2>
				<div className='panel buttons'>
					<a className='button white tumblr' href='/api/tumblr/connect'>Add Tumblr</a>
					<LinkWithQuery className='button background-green white' to='/subscribe'>Subscribe</LinkWithQuery>
				</div>
			</div>
			<div className='column'>{groups && <>
				<h2>Connected Blogs</h2>
				<div style={{
					display: 'flex',
					gap: '10px',
					marginBottom: '10px'
				}}>
					<span style={{
						display: 'block',
						lineHeight: '28px'
					}}>Hide Side Blogs</span>
					<Switch onChange={(checked) => {
						setHideSideBlogs(checked);
						if (checked) {
							localStorage.setItem('hideSideBlogs', 'true');
						} else {
							localStorage.removeItem('hideSideBlogs');
						}
					}} checked={hideSideBlogs} checkedIcon={false} uncheckedIcon={false} onColor={'#00e676'} offColor={'#bdbdbd'} />
				</div>
				{hideSideBlogs === false && Object.keys(groups).map((key) => {
					return <BlogGrid key={key} blogs={groups[key]} editable={true} />;
				})}
				{hideSideBlogs === true && <BlogGrid blogs={Object.keys(groups).map((key) => {
					return groups[key][0];
				})} editable={false} />}
			</>}</div>
		</div>;
	}
	return <div></div>;
};

const Poster = () => {
	return <div id='poster'>
		<PosterExtension />
	</div>;
};

const Blog = () => {
	const [state, dispatch] = useContext(StateContext);
	const {id} = useParams();
	const blog = useMemo(() => {
		if (state.user.blogs) {
			return state.user.blogs.find((blog) =>{
				return blog._id === id;
			});
		}
		return undefined;
	}, [id, state]);
	if (blog) {
		return <div id='blog'>
			<BlogDisplay blog={blog} />
		</div>;
	}
	return <div id='blog'></div>;
};


const Activity = () => {
	return <div id='activity'>
		<AccountActivity />
	</div>;
};

const RequireAuth = ({children}) => {
	const [state, dispatch] = useContext(StateContext);
	if (localStorage.getItem('loggedIn') && state.user === undefined) {
		return null;
	}
	return state.user ? children : <Navigate to='/' replace />;
};

const Plan = ({name, accounts, posts, fee, plan})=> {
	const [state, dispatch] = useContext(StateContext);
	const [pending, setPending] = useState(false);
	return <div className={withClasses({
		option: true,
		current: state.user.status === name.toUpperCase()
	})}>
		<div className='details'>
			<h3>{name}</h3>
			<p><strong>Accounts</strong>: {accounts}</p>
			<p><strong>Posts/Blog</strong>: {posts}</p>
			<p><strong>Fee</strong>: ${fee}/Month</p>
		</div>
		{state.user.status != 'STANDARD' && <>
			{pending === false && <PayPalButtons style={{
				layout: 'vertical'
			}} createSubscription={(data, actions) => {
				return actions.subscription.create({
					'plan_id': plan,
					// 'plan_id': process.env.REACT_APP_PAYPAL_PLAN_ID_STANDARD,
					'custom_id': state.user.username
				});
			}} onApprove={async (data, actions) => {
				setPending(true);
			}} />}
			{pending === true && <h3>Thank you for your purchase.</h3>}
			<p style={{
				textAlign: 'center',
				fontSize: '0.7em',
				fontWeight: 'bold'
			}} className='grey-dark'>Note: It may take a few minutes for your status to update after purchase.</p>
		</>}
		{state.user.status === 'STANDARD' && <>
			<p style={{
				textAlign: 'center'
			}}>
				<a className='button background-red white' href='https://paypal.com/myaccount/autopay/'>CANCEL</a>
			</p>
			<p style={{
				textAlign: 'center',
				fontSize: '0.7em',
				fontWeight: 'bold'
			}} className='grey-dark'>Note: Your status will immediately be lost when you cancel your subscription.</p>
		</>}
	</div>;
};

const Subscribe = () => {
	const [state, dispatch] = useContext(StateContext);
	const [pending, setPending] = useState(false);
	return <div id='subscribe'>
		<div className='upgrade-options'>
			<div className={withClasses({
				option: true,
				current: state.user.status === 'FREE'
			})}>
				<div className='details'>
					<h3>Free</h3>
					<p><strong>Accounts</strong>: 2</p>
					<p><strong>Posts/Blog</strong>: 300</p>
				</div>
			</div>
			<Plan name='Standard' accounts={4} posts={1200} fee={3} plan={process.env.REACT_APP_PAYPAL_PLAN_ID_STANDARD} />
			<Plan name='Unlimited' accounts={<FontAwesomeIcon icon={faInfinity} />} posts={<FontAwesomeIcon icon={faInfinity} />} fee={5} plan={process.env.REACT_APP_PAYPAL_PLAN_ID_UNLIMITED} />
		</div>
		{state.user.status === 'PREMIUM' && <div className='upgrade-options'>
			<div className={withClasses({
				option: true,
				current: true
			})}>
				<div className='details'>
					<h3>Premium</h3>
					<p><strong>Accounts</strong>: <FontAwesomeIcon icon={faInfinity} /></p>
					<p><strong>Posts/Blog</strong>: <FontAwesomeIcon icon={faInfinity} /></p>
					<p><strong>Fee</strong>: Love & Affection</p>
				</div>
			</div>
		</div>}
	</div>;
};

function App() {
	return <PayPalScriptProvider options={{
		'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
		'components': 'buttons',
		'currency': 'USD',
		'intent': 'subscription',
		'vault': true
	}}>
		<div id='wrap'>
			<Store>
				<BrowserRouter>
					<Toaster/>
					<Header />
					<Main>
						<Routes>
							<Route path='/' element={<Dashboard />} />
							<Route path='/activity' element={<RequireAuth><Activity /></RequireAuth>} />
							<Route path='/poster/:name' element={<RequireAuth><Poster /></RequireAuth>} />
							<Route path='/poster' element={<RequireAuth><Poster /></RequireAuth>} />
							<Route path='/blog/:id' element={<RequireAuth><Blog /></RequireAuth>} />
							<Route path='/subscribe' element={<RequireAuth><Subscribe /></RequireAuth>} />
						</Routes>
					</Main>
				</BrowserRouter>
			</Store>
		</div>
	</PayPalScriptProvider>;
}

export default App;
