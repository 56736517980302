import {faCheck, faMinus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import {loader} from 'graphql.macro';
import {graphRequest} from '../global/util';
import {StateSubject} from '../observer/all';
import './BlogGrid.scss';
import {ConfirmButton} from './ConfirmButton';
import {LinkWithQuery} from './LinkWithQuery';

const DisconnectMutation = loader('../graph/disconnect.mutation.graphql').loc.source.body;

export const BlogGrid = ({blogs, editable}) => {
	const disconnect = async (group) => {
		try {
			const data = await graphRequest(DisconnectMutation, {
				group
			});
			StateSubject.next();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	};

	return <div className='blog-grid'>
		<div className='grid'>
			{blogs.map((blog) => {
				return <div key={blog._id} className='blog-card'>
					<LinkWithQuery to={`/blog/${blog._id}`} className='blog-icon' style={{
						backgroundImage: `url("https://api.tumblr.com/v2/blog/${blog._id}/avatar/512")`
					}}><></></LinkWithQuery>
					<div className='blog-name'>
						<a href={`https://${blog.name}.tumblr.com/`}>{blog.name}</a>
					</div>
				</div>;
			})}
		</div>
		{editable && <div className='blog-unlink'>
			<ConfirmButton
				className='background-red white'
				confirmationComponent={<FontAwesomeIcon icon={faCheck} />}
				onConfirmation={disconnect.bind(null, blogs[0].group)}
			><FontAwesomeIcon icon={faMinus} /></ConfirmButton>
		</div>}
	</div>;
};
