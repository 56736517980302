import {useEffect, useState} from 'react';
import {ToasterInput, ToasterSubject} from '../observer/all';
import {LinkWithQuery} from './LinkWithQuery';
import './Toaster.scss';

type Message = {
	_id: number;
	input: ToasterInput;
}

type ToasterMessageProps = {
	message: Message;
	onRemove: (message: Message) => void;
}

const ToasterMessage = ({message, onRemove}: ToasterMessageProps) => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			onRemove(message);
		}, 6000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);
	return <div className={`toaster-message ${message.input.type}`}>
		<span>{message.input.message}</span>
		{message.input.href && <div className='action'>
			<LinkWithQuery to={message.input.href}>View</LinkWithQuery>
		</div>}
	</div>;
};

export const Toaster = () => {
	const [messages, setMessages] = useState<Message[]>([]);

	const remove = ({_id}) => {
		setMessages((messages) => {
			return messages.filter((message) => {
				return message._id != _id;
			});
		});
	};

	useEffect(() => {
		const observer = ToasterSubject.subscribe((input) => {
			setMessages((value) => {
				return [{
					_id: Date.now(),
					input
				}, ...value];
			});
		});
		return () => {
			observer.unsubscribe();
		};
	}, []);

	return <div id='toaster'>
		{messages.map((message) => {
			return <ToasterMessage key={message._id} message={message} onRemove={remove} />;
		})}
	</div>;
};
