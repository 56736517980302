
import {faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {loader} from 'graphql.macro';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import Switch from 'react-switch';
import {graphRequest} from '../global/util';
import './BlogDisplay.scss';
import {BlogQueue} from './BlogQueue';
import {BlogSchedule} from './BlogSchedule';
const PreferencesQuery = loader('../graph/preferences.query.graphql').loc.source.body;
const ShuffleMutation = loader('../graph/shuffle.mutation.graphql').loc.source.body;

const withClasses = (object: any) => {
	return Object.keys(object).filter((key) => {
		return object[key];
	})
		.join(' ');
};

const Collapsible: FunctionComponent<{
	initCollapsed: boolean;
	title: string;
}> = ({children, initCollapsed, title}) => {
	const [isCollapsed, setIsCollapsed] = useState(initCollapsed);
	return <div className={withClasses({
		collapsible: true,
		collapsed: isCollapsed
	})}>
		<h2 className='collapsible-header' onClick={() => {
			setIsCollapsed((isCollapsed) => {
				return !isCollapsed;
			});
		}}><FontAwesomeIcon icon={isCollapsed ? faArrowDown : faArrowUp} /> {title}</h2>
		<div className='collapsible-body' style={{
			display: (isCollapsed) ? 'none' : 'block'
		}}>{children}</div>
	</div>;
};

export const BlogDisplay = ({blog}) => {
	const [preferences, setPreferences] = useState({
		shuffled: false
	});

	const getPreferences = useCallback(async (blog) => {
		const data = await graphRequest(PreferencesQuery, {
			'to': blog._id
		});
		setPreferences(data.data.preferences);
	}, [blog]);

	const shuffle = useCallback(async (blog, shuffled) => {
		setPreferences((preferences) => {
			return {
				...preferences,
				shuffled
			};
		});
		const data = await graphRequest(ShuffleMutation, {
			'to': blog._id,
			shuffled
		});
		return getPreferences(blog);
	}, [blog]);

	useEffect(() => {
		getPreferences(blog);
	}, [blog]);

	return <div className='blog-display'>
		<div className='blog-header'>
			<div className='blog-icon' style={{
				backgroundImage: `url("https://api.tumblr.com/v2/blog/${blog._id}/avatar/512")`
			}} />
			<h1>
				<a href={`https://${blog.name}.tumblr.com/`} target='_blank' rel='noreferrer'>{blog.name}</a>
			</h1>
		</div>
		{preferences && <div className='preferences'>
			<div className='cell'>
				<label>Shuffle</label>
				<div><Switch onChange={(shuffled) => {
					return shuffle(blog, shuffled);
				}} checked={preferences.shuffled} checkedIcon={false} uncheckedIcon={false} onColor={'#00e676'} offColor={'#bdbdbd'} /></div>
			</div>
		</div>}
		<div><BlogQueue blog={blog} shuffled={preferences.shuffled} /></div>
		<Collapsible title='Schedule' initCollapsed={false}>
			<BlogSchedule blog={blog} />
		</Collapsible>
	</div>;
};
