import {createContext} from 'react';

export enum ActionType {
	UPDATE_USER
}

export type Action = {
	type: ActionType;
	payload: any;
}


type State = {
	user?: {
		email: string;
		username: string;
		status: 'FREE' | 'STANDARD' | 'UNLIMITIED' | 'PREMIUM';
		blogs: {
			_id: string;
			name: string;
			schedule: {
				enabled: boolean;
				slots: {
					status: 0 | 1;
					day: string;
					start: number | string;
					end: number | string;
				}[];
			};
			parent: {
				_id: string;
			};
			group: string;
		}[];
	};
}


export const StateReducer = (state: State, action: Action) => {
	switch (action.type) {
		case ActionType.UPDATE_USER:
			return {
				...state,
				user: action.payload
			};
		default: return state;
	}
};

type ContextType = [State, React.Dispatch<Action>];

export const StateContext = createContext<ContextType>(null);
