import {faBan, faCheck, faExchangeAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import {formatDistanceToNow} from 'date-fns';
import {loader} from 'graphql.macro';
import {List} from 'immutable';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {StateContext} from '../global/store';
import {graphRequest} from '../global/util';
import {ToasterSubject} from '../observer/all';
import './AccountActivity.scss';
import {ConfirmButton} from './ConfirmButton';
import {LinkWithQuery} from './LinkWithQuery';
const ActivityQuery = loader('../graph/activity.query.graphql').loc.source.body;
const TransferMutation = loader('../graph/transfer.mutation.graphql').loc.source.body;
const DequeueMutation = loader('../graph/dequeue.mutation.graphql').loc.source.body;

type Notication = {
	to: {
		name: string;
	};
	from: {
		name: string;
	};
	count: number;
	createdAt: string;
	group: string;
}

enum NotificationState {
	DEFAULT,
	TRANSFER
}

const Notification = ({notication, onUpdate}) => {
	const [appState] = useContext(StateContext);
	const [state, setState] = useState(NotificationState.DEFAULT);

	const toRef = useRef<HTMLSelectElement>(undefined);

	const transfer = useCallback(async () => {
		try {
			const data = await graphRequest(TransferMutation, {
				'to': toRef.current.value,
				'group': notication.group,
				'from': notication.to._id
			});
			ToasterSubject.next({
				type: 'success',
				message: 'Successfully Transferred',
				href: `/blog/${toRef.current.value}`
			});
			setState(NotificationState.DEFAULT);
			onUpdate();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, []);

	const dequeue = useCallback(async () => {
		try {
			const data = await graphRequest(DequeueMutation, {
				to: notication.to._id,
				group: notication.group
			});
			ToasterSubject.next({
				type: 'success',
				message: 'Successfully Deleted'
			});
			onUpdate();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, []);

	return <div className='notification'>
		<p>
			<LinkWithQuery to={`/blog/${notication.to._id}`}>{notication.to.name}</LinkWithQuery>
			<span> queued <strong>{notication.count}</strong> posts from </span>
			<a href={`https://${notication.from.name}.tumblr.com/`} target='_blank' rel='noreferrer'>{notication.from.name}</a>
		</p>
		<p className='date'>{formatDistanceToNow(new Date(notication.createdAt))} Ago</p>
		{state === NotificationState.DEFAULT && <div className='button-group'>
			<button className='background-pink white' onClick={() => {
				setState(NotificationState.TRANSFER);
			}}><FontAwesomeIcon icon={faExchangeAlt}/></button>
			<ConfirmButton className='background-red white' onConfirmation={dequeue.bind(null)} confirmationComponent={<FontAwesomeIcon icon={faCheck} />}><FontAwesomeIcon icon={faTrash}/></ConfirmButton>
		</div>}
		{state === NotificationState.TRANSFER && <div className='transfer'>
			<select ref={toRef}>
				{appState.user.blogs.filter((blog) => {
					return blog._id !== notication.to._id;
				}).map((blog) => {
					return <option key={blog._id} value={blog._id}>{blog.name}</option>;
				})}
			</select>
			<button className='background-green white' onClick={transfer.bind(null)}>
				<FontAwesomeIcon icon={faCheck} />
			</button>
			<button className='background-red white' onClick={() => {
				setState(NotificationState.DEFAULT);
			}}>
				<FontAwesomeIcon icon={faBan} />
			</button>
		</div>}
	</div>;
};

export const AccountActivity = () => {
	const [notications, setNotifications] = useState<List<Notication>>(List());
	const [counts, setCounts] = useState({
		toCount: 0,
		fromCount: 0
	});

	const fetch = useCallback(async () => {
		try {
			const data = await graphRequest(ActivityQuery, {});
			setNotifications(List(data.data.activity.notifications));
			setCounts({
				toCount: data.data.activity.toCount,
				fromCount: data.data.activity.fromCount
			});
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		fetch();
	}, []);

	return <div>
		<h2>Activity</h2>
		<div className='grid'>
			<div className='content'>
				<div className='left'>
					<div className='card background-cyan-light' style={{
						marginBottom: '20px'
					}}>
						<h2>{counts.toCount}</h2>
						<h3>Total Posts Queued</h3>
					</div>
					<div className='card background-green-light'>
						<h2>{counts.fromCount}</h2>
						<h3>Total Posts Queued From</h3>
					</div>
				</div>
				<div className='right'>
					<div className='feed'>
						{notications.map((notication) => {
							return <Notification key={notication.group} notication={notication} onUpdate={fetch.bind(null)} />;
						})}
					</div>
				</div>
			</div>
		</div>
	</div>;
};
