import axios from 'axios';
import {ToasterSubject} from '../observer/all';

export const graphRequest = async (query, variables, showErrors = true) => {
	try {
		const {data} = await axios.post(`/api/graphql${window.location.search}`, {
			query,
			variables
		});
		if (data.errors && showErrors) {
			ToasterSubject.next({
				type: 'error',
				message: data.errors[0].message
			});
			throw new Error(data.errors[0].message);
		}
		return data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.errors && showErrors) {
			ToasterSubject.next({
				type: 'error',
				message: error.response.data.errors[0].message
			});
		}
		throw error;
	}
};
