import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0
});

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}

ReactDOM.render(<React.StrictMode>
	<App />
</React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
