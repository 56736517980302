import * as Sentry from '@sentry/react';
import {loader} from 'graphql.macro';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Switch from 'react-switch';
import configuration from '../config.json';
import {graphRequest} from '../global/util';
import './BlogSchedule.scss';

const ScheduleQuery = loader('../graph/schedule.query.graphql').loc.source.body;
const ScheduleMutation = loader('../graph/schedule.mutation.graphql').loc.source.body;
const AlterMutation = loader('../graph/alter.mutation.graphql').loc.source.body;

const times = ['00:00 AM', '00:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM', '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '13:00 PM', '13:30 PM', '14:00 PM', '14:30 PM', '15:00 PM', '15:30 PM', '16:00 PM', '16:30 PM', '17:00 PM', '17:30 PM', '18:00 PM', '18:30 PM', '19:00 PM', '19:30 PM', '20:00 PM', '20:30 PM', '21:00 PM', '21:30 PM', '22:00 PM', '22:30 PM', '23:00 PM', '23:30 PM'];

const parseMinutes = (minutes) => {
	const date = new Date(0);
	date.setMinutes(minutes);
	return `${date.toISOString().substr(11, 5)} ${(minutes < 1440 && minutes >= 720 ? 'PM' : 'AM')}`;
};

const Slot = ({blog, slot, onSlotChange}) => {
	const [pristine, setPristine] = useState<boolean>(true);

	const [enabled, setEnabled] = useState(slot.enabled);

	const startRef = useRef<HTMLSelectElement>();
	const endRef = useRef<HTMLSelectElement>();
	const intervalRef = useRef<HTMLSelectElement>();

	const start = useMemo(() => {
		return parseMinutes(slot.start);
	}, [slot]);

	const end = useMemo(() => {
		return parseMinutes(slot.end);
	}, [slot]);

	const count = useMemo(() => {
		return Math.floor((slot.end - slot.start) / slot.interval);
	}, [slot]);

	const onChange = () => {
		setPristine(false);
	};

	const save = async (event) => {
		event.preventDefault();
		try {
			const data = await graphRequest(ScheduleMutation, {
				to: blog._id,
				schedule: {
					day: slot.day,
					start: times.indexOf(startRef.current.value) * 30,
					end: times.indexOf(endRef.current.value) * 30,
					interval: parseInt(intervalRef.current.value)
				}
			});
			setPristine(true);
			onSlotChange();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	};

	const alter = async (enabled: boolean) => {
		try {
			setEnabled(enabled);
			const data = await graphRequest(AlterMutation, {
				to: blog._id,
				enabled,
				day: slot.day
			});
			onSlotChange();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		}
	};

	return <div className={'schedule-card'}>
		<h3 className='schedule-day'>{slot.day} <div className='schedule-day switch'>
			<Switch onChange={alter.bind(undefined, !enabled)} checked={enabled} checkedIcon={false} uncheckedIcon={false} onColor={'#00e676'} offColor={'#bdbdbd'} /></div></h3>
		<div className='schedule-items'>
			<div className='schedule-item'>
				<div>Start</div>
				<div>
					<select ref={startRef} defaultValue={start} onChange={onChange}>
						{times.map((time) => {
							return <option key={time} value={time}>{time}</option>;
						})}
					</select>
				</div>
			</div>
			<div className='schedule-item'>
				<div>End</div>
				<div>
					<select ref={endRef} defaultValue={end} onChange={onChange}>
						{times.map((time) => {
							return <option key={time} value={time}>{time}</option>;
						})}
					</select>
				</div>
			</div>
		</div>
		<div className='schedule-items'>
			<select ref={intervalRef} defaultValue={slot.interval} onChange={onChange}>
				{Object.keys(configuration.schedule.intervals).map((key) => {
					return <option key={key} value={key}>{configuration.schedule.intervals[key]}</option>;
				})}
			</select>
		</div>
		<div className='posts-per'>{count} Posts Per Day</div>
		<div className='schedule-buttons'>
			{pristine === false && <button type='button' onClick={save} className='background-green white'>Save</button>}
			{/* {enabled === true && <button type='button' onClick={alter.bind(undefined, false)} className='background-red white'>Disable</button>}
			{enabled === false && <button type='button' onClick={alter.bind(undefined, true)} className='background-green white'>Enable</button>} */}

		</div>
	</div>;
};

export const BlogSchedule = ({blog}) => {
	const [slots, setSlots] = useState([]);

	const fetch = useCallback(() => {
		(async () => {
			const data = await graphRequest(ScheduleQuery, {
				to: blog._id
			});
			setSlots(data.data.schedule.slots);
		})().catch((error) => {
			console.log(error);
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		});
	}, []);

	useEffect(fetch, [blog]);

	return <div className='blog-schedule'>
		{slots.map((slot, index) => {
			return <Slot onSlotChange={fetch.bind(undefined)} key={index} blog={blog} slot={slot} />;
		})}
	</div>;
};
