import {Subject} from 'rxjs';

export type ToasterInput = {
	message: string;
	type: 'success' | 'error';
	href?: string;
}

export const StateSubject = new Subject<void>();

export const ToasterSubject = new Subject<ToasterInput>();
