import * as Sentry from '@sentry/react';
import axios from 'axios';
import {loader} from 'graphql.macro';
import {List, Map} from 'immutable';
import {Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useLocation, useParams} from 'react-router-dom';
import Switch from 'react-switch';
import configuration from '../config.json';
import {StateContext} from '../global/store';
import {graphRequest} from '../global/util';
import {ToasterSubject} from '../observer/all';
import './PosterExtension.scss';
const QueueMutation = loader('../graph/queue.graphql').loc.source.body;
declare let $: any;

const months = {
	'January': '01',
	'February': '02',
	'March': '03',
	'April': '04',
	'May': '05',
	'June': '06',
	'July': '07',
	'August': '08',
	'September': '09',
	'October': '10',
	'November': '11',
	'December': '12'
};

const withClasses = (object: any) => {
	return Object.keys(object).filter((key) => {
		return object[key];
	})
		.join(' ');
};

const ParsedBlock = ({block, index}) => {
	if (block?.media && block.media.length) {
		block.media[0] = block.media.reduce((prev, cur) => {
			if (!prev) return cur;
			else if (prev && prev.width > cur.width && cur.width > 125) return cur;
			return prev;
		});
	}
	return <>
		{block.type === 'text' && <div className='text'>
			{block.subtype === 'heading1' && <h4>{block.text}</h4>}
			{block.subtype === 'quote' && <h4>{block.text}</h4>}
			{block.subtype === 'indented' && <strong>{block.text}</strong>}
			{index === 0 && block.blog && block.blog.name && <p>{block.blog.name}</p>}
			{block.subtype === 'chat' && <blockquote>{block.text}</blockquote>}
			{block.subtype === undefined && <p>{block.text}</p>}
		</div>}
		{block.type === 'link' && block.subtype === undefined && <div className='link'>
			{block.title && <span>{block.title}</span>}
		</div>}
		{block.type === 'image' && block.media && block.media.length && <div className='image' style={{
			backgroundImage: `url('${block.media[0].url}')`
		}} />}
		{block.type === 'video' && block.poster && block.poster.length && <div className='video' style={{
			backgroundImage: `url('${block.poster[0].url}')`
		}} />}
	</>;
};

const BlocksPost = ({post}) => {
	return <div className='wrap'>
		{post.content && post.content.map((block, index) =>{
			return <ParsedBlock key={index} block={block} index={index} />;
		})}
		{post.trail && post.trail.map((trail, tindex) =>{
			return <Fragment key={tindex}>
				{trail.content && trail.content.map((block, index) => {
					return <ParsedBlock key={index} block={block} index={index} />;
				})}
			</Fragment>;
		})}
	</div>;
};

const Post = ({post}) => {
	return <div id={`post-${post.id_string}`} className={`post ${post.type} ${isMobile ? 'mobile' : 'desktop'}`} data-id={post.id_string}>
		{/* {post.selected === true && } */}
		<a className={withClasses({
			cover: true,
			selected: post.selected
		})} href={post.post_url} target='_blank' rel='noreferrer'>
			<span>{post.note_count} Notes</span>
		</a>
		{post.type === 'blocks' && <BlocksPost post={post} />}
	</div>;
};

const Selectable = ({children, onSelected}) => {
	const selectableRef = useRef<HTMLDivElement>();
	useEffect(() => {
		if (selectableRef.current) {
			const node = $(selectableRef.current);
			node.selectable({
				filter: 'div.post',
				selected: (event: Event, ui: { selected?: Element }) => {
					onSelected(ui.selected && ui.selected.getAttribute('data-id'));
				}
			});
			return () => {
				node.selectable('destroy');
			};
		}
	}, [selectableRef]);
	return <div ref={selectableRef} className='posts'>{children}</div>;
};

type Action = {
	type: 'add' | 'set' | 'select' | 'select-by' | 'deselect' | 'clean';
	payload?: any | any[];
}

const postsReducer = (state: List<Map<string, any>>, action: Action) => {
	switch (action.type) {
		case 'add':
			return state.push(...action.payload);
		case 'set':
			return List<Map<string, any>>(action.payload);
		case 'select':
			return state.update(state.findIndex((item) => {
				console.log(item.get('id_string'), action.payload);
				return item.get('id_string') == action.payload;
			}), (item) => {
				return item.update('selected', (value) => {
					return !value;
				});
			});
		case 'select-by':
			return state.map((item) => {
				if (item.get('note_count') > action.payload) {
					return item.set('selected', true);
				}
				return item;
			});
		case 'deselect':
			return state.map((item) => {
				return item.set('selected', false);
			});
		case 'clean':
			return state.clear();
		default:
			return state;
	}
};

const useQuery = () => {
	const {search} = useLocation();
	return useMemo(() => {
		return new URLSearchParams(search);
	}, [search]);
};

const ArchivePoster = () => {
	const fromRef = useRef<HTMLInputElement>();
	const tagRef = useRef<HTMLInputElement>();
	const toRef = useRef<HTMLSelectElement>();
	const typeRef = useRef<HTMLSelectElement>();
	const yearRef = useRef<HTMLSelectElement>();
	const monthRef = useRef<HTMLSelectElement>();

	const linksRef = useRef<any>(undefined);

	const [isNetworking, setNetworking] = useState(false);
	const {name} = useParams();

	// eslint-disable-next-line camelcase
	const api_key = useMemo(() => {
		return configuration.keys[Math.floor(Math.random() * configuration.keys.length)];
	}, []);

	const [useDateFilter, setUseDateFilter] = useState(false);
	const [removeCaptions, setRemoveCaptions] = useState(false);
	const [posts, dispatch] = useReducer(postsReducer, List<Map<string, any>>());

	const [state] = useContext(StateContext);

	const selected: List<Map<string, any>> = useMemo(() => {
		return posts.filter((post) => {
			return post.get('selected') === true;
		});
	}, [posts]);

	const fetch = useCallback(async (clear = false) => {
		if (isNetworking) return;
		setNetworking(true);
		if (fromRef.current.value) {
			console.log(clear, linksRef.current);
			try {
				const params: {[key: string]: any} = {
				// eslint-disable-next-line camelcase
					api_key,
					npf: true
				};
				if (clear === true) {
					if (useDateFilter) {
						params.before = (Date.parse(`${yearRef.current.value}-${months[monthRef.current.value]}`)) / 1000;
					}
				} else {
					params.page_number = linksRef.current.page_number;
				}
				if (tagRef.current.value) {
					params.tag = tagRef.current.value;
				}
				const posts = [];
				const load = async (limit = 20, params) => {
					if (params.page_number === false) return;
					const {data} = await axios({
						method: 'get',
						url: `https://api.tumblr.com/v2/blog/${fromRef.current.value}/posts${typeRef.current.value}`,
						params: {
							...params,
							limit
						}
					});
					if (data.response) {
						if (data.response._links && data.response._links.next) {
							params.page_number = data.response._links.next.query_params.page_number;
						} else {
							params.page_number = false;
						}
						posts.push(...data.response.posts.map((item) => {
							return Map(item);
						}));
					}
				};
				if (state.user.status === 'FREE') {
					await load(20, params);
					await load(20, params);
					await load(10, params);
				} else {
					await load(20, params);
					await load(20, params);
					await load(20, params);
					await load(20, params);
					await load(20, params);
				}
				linksRef.current = params;
				dispatch({
					type: clear ? 'set' : 'add',
					payload: posts
				});
				setNetworking(false);
			} catch (error) {
				console.log(error);
				if (error.response && error.response.status === 404) {
					console.log(error.response);
					ToasterSubject.next({
						message: 'Blog Not Found',
						type: 'error'
					});
					dispatch({
						type: 'clean'
					});
					setNetworking(false);
				} else {
					Sentry.captureException(error, {
						contexts: {
							data: {
								response: JSON.stringify(error.response && error.response.data)
							}
						}
					});
				}
			}
		}
	}, [useDateFilter]);

	useEffect(() => {
		if (name) {
			fetch(true);
		}
	}, [name]);

	const select = (id) => {
		return dispatch({
			type: 'select',
			payload: id
		});
	};

	const queue = useCallback((posts: List<Map<string, any>>) => {
		if (posts.size === 0) return;
		if (isNetworking) return;
		setNetworking(true);
		(async () => {
			const data = await graphRequest(QueueMutation, {
				from: posts.first().get('blog').uuid,
				to: toRef.current.value,
				posts: posts.map((post) => {
					return {
						id: post.get('id_string'),
						key: post.get('reblog_key')
					};
				}).toArray(),
				removeCaptions
			});
			setNetworking(false);
			ToasterSubject.next({
				message: `Successfully Queued ${posts.size} Posts From ${fromRef.current.value}`,
				type: 'success'
			});
			return dispatch({
				type: 'deselect'
			});
		})().catch((error) => {
			Sentry.captureException(error, {
				contexts: {
					data: {
						response: JSON.stringify(error.response && error.response.data)
					}
				}
			});
		});
	}, [isNetworking, removeCaptions]);

	const [hideSideBlogs] = useState<boolean>(localStorage.getItem('hideSideBlogs') ? true : false);

	const blogs = useMemo(() => {
		if (hideSideBlogs) {
			const groups = state.user.blogs.reduce((prev, curr) => {
				if (prev[curr.group] === undefined) {
					prev[curr.group] = [];
				}
				prev[curr.group].push(curr);
				return prev;
			}, {});
			return Object.keys(groups).map((key) => {
				return groups[key][0];
			});
		}
		return state.user.blogs;
	}, []);

	return <div className='poster-extension'>
		<h2>Poster</h2>
		<div className='header'>
			<select ref={typeRef} onChange={fetch.bind(undefined, true)}>
				<option value='/'>All</option>
				<option value='/text'>TEXT</option>
				<option value='/photo'>PHOTO</option>
				<option value='/quote'>QUOTE</option>
			</select>
			<input ref={fromRef} defaultValue={name} type='text' placeholder='username' />
			<input className='tag' ref={tagRef} type='text' placeholder='tag' />
			<button onClick={fetch.bind(undefined, true)}>LOAD</button>
		</div>
		<div className='header'>
			<div>
				<label>
					<span style={{
						fontWeight: 'bold'
					}}>Date Filter</span>
					<Switch onChange={setUseDateFilter} checked={useDateFilter} checkedIcon={false} uncheckedIcon={false} onColor={'#00e676'} offColor={'#bdbdbd'} />
				</label>
			</div>
			<div>
				<label>
					<span style={{
						fontWeight: 'bold'
					}}>Remove Captions</span>
					<Switch onChange={setRemoveCaptions} checked={removeCaptions} checkedIcon={false} uncheckedIcon={false} onColor={'#00e676'} offColor={'#bdbdbd'} />
				</label>
			</div>
		</div>
		{useDateFilter && <div className='header'>
			<select ref={yearRef}>
				{['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'].map((date) => {
					return <option key={date} value={date}>{date}</option>;
				})}
			</select>
			<select ref={monthRef}>
				{['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((date) => {
					return <option key={date} value={date}>{date}</option>;
				})}
			</select>
		</div>}
		<Selectable onSelected={select}>
			{posts.map((post) => {
				return <Post key={post.get('id')} post={post.toObject()} />;
			})}
		</Selectable>
		<div className='footer'>
			<div className='category'><button onClick={fetch.bind(undefined, false)}>LOAD</button>
				<button onClick={() => {
					dispatch({
						type: 'select-by',
						payload: -1
					});
				}}>ALL</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 1000
					});
				}}>1K+</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 10000
					});
				}}>10K+</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 100000
					});
				}}>100K+</button>
				<button onClick={() => {
					dispatch({
						type: 'deselect'
					});
				}}>CLEAR</button></div>
			<div className='category'>
				<select ref={toRef}>
					{blogs.map((blog) => {
						return <option key={blog._id} value={blog._id}>{blog.name}</option>;
					})}
				</select>
				<button className={withClasses({
					'white': true,
					'background-green': selected.size > 0,
					'background-red': selected.size === 0
				})} onClick={queue.bind(null, selected)}>{selected.size} Posts</button>
			</div>
		</div>
	</div>;
};

const DashboardPoster = () => {
	const toRef = useRef<HTMLSelectElement>();
	const linksRef = useRef<any>(undefined);
	const [isNetworking, setNetworking] = useState(false);
	const query = useQuery();

	const {token, sid} = useMemo(() => {
		return {
			token: query.get('token'),
			sid: query.get('sid')
		};
	}, []);

	const [useDateFilter, setUseDateFilter] = useState(false);
	const [removeCaptions, setRemoveCaptions] = useState(false);
	const [posts, dispatch] = useReducer(postsReducer, List<Map<string, any>>());

	const [state] = useContext(StateContext);

	const selected: List<Map<string, any>> = useMemo(() => {
		return posts.filter((post) => {
			return post.get('selected') === true;
		});
	}, [posts]);

	const fetch = useCallback(async (clear = false) => {
		chrome.runtime.sendMessage('dipohbepdlapbfpcoopheenieiebfccd', {
			action: 'FETCH',
			source: 'queuesystem'
		},
		function(response) {

		});
		// if (isNetworking) return;
		// setNetworking(true);
		// const posts = [];
		// try {
		// 	const {data} = await axios.get('/api/tumblr/dashboard', {
		// 		params: {
		// 			sid,
		// 			token
		// 		}
		// 	});
		// 	if (data?.response?.timeline?.elements) {
		// 		console.log();
		// 		posts.push(...data.response.timeline.elements.filter((el) => {
		// 			return el.object_type === 'post';
		// 		}).map((item) => {
		// 			return Map(item);
		// 		}));
		// 	}
		// 	dispatch({
		// 		type: 'add',
		// 		payload: posts
		// 	});
		// } catch (error) {
		// 	console.log(error);
		// 	if (error.response && error.response.status === 404) {
		// 		console.log(error.response);
		// 		ToasterSubject.next({
		// 			message: 'Blog Not Found',
		// 			type: 'error'
		// 		});
		// 		dispatch({
		// 			type: 'clean'
		// 		});
		// 		setNetworking(false);
		// 	} else {
		// 		Sentry.captureException(error, {
		// 			contexts: {
		// 				data: {
		// 					response: JSON.stringify(error.response && error.response.data)
		// 				}
		// 			}
		// 		});
		// 	}
		// }

		// if (fromRef.current.value) {
		// 	console.log(clear, linksRef.current);
		// 	try {
		// 		const params: {[key: string]: any} = {
		// 		// eslint-disable-next-line camelcase
		// 			api_key,
		// 			npf: true
		// 		};
		// 		if (clear === true) {
		// 			if (useDateFilter) {
		// 				params.before = (Date.parse(`${yearRef.current.value}-${months[monthRef.current.value]}`)) / 1000;
		// 			}
		// 		} else {
		// 			params.page_number = linksRef.current.page_number;
		// 		}
		// 		if (tagRef.current.value) {
		// 			params.tag = tagRef.current.value;
		// 		}
		// 		const posts = [];
		// 		const load = async (limit = 20, params) => {
		// 			if (params.page_number === false) return;
		// 			const {data} = await axios({
		// 				method: 'get',
		// 				url: `https://api.tumblr.com/v2/blog/${fromRef.current.value}/posts${typeRef.current.value}`,
		// 				params: {
		// 					...params,
		// 					limit
		// 				}
		// 			});
		// 			if (data.response) {
		// 				if (data.response._links && data.response._links.next) {
		// 					params.page_number = data.response._links.next.query_params.page_number;
		// 				} else {
		// 					params.page_number = false;
		// 				}
		// 				posts.push(...data.response.posts.map((item) => {
		// 					return Map(item);
		// 				}));
		// 			}
		// 		};
		// 		if (state.user.status === 'FREE') {
		// 			await load(20, params);
		// 			await load(20, params);
		// 			await load(10, params);
		// 		} else {
		// 			await load(20, params);
		// 			await load(20, params);
		// 			await load(20, params);
		// 			await load(20, params);
		// 			await load(20, params);
		// 		}
		// 		linksRef.current = params;
		// 		dispatch({
		// 			type: clear ? 'set' : 'add',
		// 			payload: posts
		// 		});
		// 		setNetworking(false);
		// 	} catch (error) {
		// 	}
		// }
	}, [useDateFilter]);

	useEffect(() => {
		if (token && sid) {
			fetch(true);
		}
	}, [token, sid]);

	const select = (id) => {
		return dispatch({
			type: 'select',
			payload: id
		});
	};

	const queue = useCallback((posts: List<Map<string, any>>) => {
		console.log({
			from: 'special:dashboard',
			to: toRef.current.value,
			posts: posts.map((post) => {
				return {
					id: post.get('idString'),
					key: post.get('reblogKey'),
					from: post.get('blog')?.uuid
				};
			}).toArray(),
			removeCaptions
		});
		// if (posts.size === 0) return;
		// if (isNetworking) return;
		// setNetworking(true);
		// (async () => {
		// 	const data = await graphRequest(QueueMutation, {
		// 		from: 'special:dashboard',
		// 		to: toRef.current.value,
		// 		posts: posts.map((post) => {
		// 			return {
		// 				id: post.get('id_string'),
		// 				key: post.get('reblog_key'),
		// 				from: post.get('blog')?.uuid
		// 			};
		// 		}).toArray(),
		// 		removeCaptions
		// 	});
		// 	setNetworking(false);
		// 	ToasterSubject.next({
		// 		message: `Successfully Queued ${posts.size} Posts`,
		// 		type: 'success'
		// 	});
		// 	return dispatch({
		// 		type: 'deselect'
		// 	});
		// })().catch((error) => {
		// 	Sentry.captureException(error, {
		// 		contexts: {
		// 			data: {
		// 				response: JSON.stringify(error.response && error.response.data)
		// 			}
		// 		}
		// 	});
		// });
	}, [isNetworking, removeCaptions]);

	const x = useCallback((request) => {
		const posts = [];
		if (request?.data?.posts) {
			posts.push(...request.data.posts.map((item) => {
				item.note_count = item.noteCount;
				item.id_string = item.idString;
				item.post_url = item.postUrl;
				return Map(item);
			}));
			dispatch({
				type: 'add',
				payload: posts
			});
		}
	}, []);

	useEffect(() => {
		window.addEventListener('message', x);
		return () => {
			window.removeEventListener('message', x);
		};
	}, []);

	const [hideSideBlogs] = useState<boolean>(localStorage.getItem('hideSideBlogs') ? true : false);

	const blogs = useMemo(() => {
		if (hideSideBlogs) {
			const groups = state.user.blogs.reduce((prev, curr) => {
				if (prev[curr.group] === undefined) {
					prev[curr.group] = [];
				}
				prev[curr.group].push(curr);
				return prev;
			}, {});
			return Object.keys(groups).map((key) => {
				return groups[key][0];
			});
		}
		return state.user.blogs;
	}, []);

	return <div className='poster-extension'>
		<h2>Dashboard Poster</h2>
		<Selectable onSelected={select}>
			{posts.map((post) => {
				return <Post key={post.get('id')} post={post.toObject()} />;
			})}
		</Selectable>
		<div className='footer'>
			<div className='category'><button onClick={fetch.bind(undefined, false)}>LOAD</button>
				<button onClick={() => {
					dispatch({
						type: 'select-by',
						payload: -1
					});
				}}>ALL</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 1000
					});
				}}>1K+</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 10000
					});
				}}>10K+</button>
				<button className='tablet' onClick={() => {
					dispatch({
						type: 'select-by',
						payload: 100000
					});
				}}>100K+</button>
				<button onClick={() => {
					dispatch({
						type: 'deselect'
					});
				}}>CLEAR</button></div>
			<div className='category'>
				<select ref={toRef}>
					{blogs.map((blog) => {
						return <option key={blog._id} value={blog._id}>{blog.name}</option>;
					})}
				</select>
				<button className={withClasses({
					'white': true,
					'background-green': selected.size > 0,
					'background-red': selected.size === 0
				})} onClick={queue.bind(null, selected)}>{selected.size} Posts</button>
			</div>
		</div>
	</div>;
};

export const PosterExtension = () => {
	const query = useQuery();

	if (query.get('source') === 'dashboard') {
		return <DashboardPoster />;
	}
	return <ArchivePoster />;
};
