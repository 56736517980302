import {useEffect, useState} from 'react';

type ConfirmButtonState = 1 | 2;

export const ConfirmButton = ({className, children, confirmationComponent, onConfirmation}) => {
	const [state, setState] = useState<ConfirmButtonState>(1);

	useEffect(() => {
		const i = setTimeout(() => {
			setState(1);
		}, 5000);
		return () => {
			clearTimeout(i);
		};
	}, [state]);

	return <button onClick={(event) => {
		if (state === 1) {
			setState((state) => {
				if (state === 1) {
					return 2;
				}
			});
		} else {
			onConfirmation(event);
		}
	}} className={className}>
		{state === 2 && confirmationComponent}
		{state === 1 && children}
	</button>;
};
